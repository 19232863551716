/**
* Generated automatically at built-time (2024-08-16T09:45:05.056Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "vinkyl-guide",templateKey: "sites/98-a19ba03c-c0ed-4666-9fb1-398ec68a308a"};